import SingleLayout from '@/layout/SingleLayout.vue'
import LoginLayout from '@/views/login/Layout.vue'
import BaseLayout from '@/layout/BaseLayout.vue'

/** 普通路由（无需权限的路由） */
export const rootRoute = {
  path: '/',
  redirect: '/index'
}

export const loginRegisterRoute = {
  path: '/',
  name: 'LoginRegister',
  component: LoginLayout,
  children: [
    {
      path: 'login',
      name: 'Login',
      component: () => import('@/views/login/LoginBody.vue')
    },
    {
      path: 'register',
      name: 'Register',
      component: () => import('@/views/login/RegisterBody.vue')
    }
  ]
}

const homeRoute = {
  path: '/',
  component: BaseLayout,
  props: { sider: false, breadcrumb: false },
  meta: {
    hidden: true
  },
  children: [
    {
      path: '/index',
      name: 'HomeIndex',
      meta: {
        title: '首页',
        permissions: 'no'
      },
      component: () => import('@/views/home/HomeIndex.vue')
    },
    {
      path: '/platDetail/:id/:type',
      name: 'PlatDetail',
      meta: {
        title: '平台详情',
        permissions: 'no'
      },
      props: true,
      component: () => import('@/views/home/PlatDetail.vue')
    },
    {
      path: '/appDetail',
      name: 'APPDetail',
      meta: {
        title: '工业应用详情',
        permissions: 'no'
      },
      component: () => import('@/views/home/AppDetail.vue')
    },
    {
      path: '/clientSupport',
      name: 'ClientSupport',
      meta: {
        title: '客户支持',
        permissions: 'no'
      },
      component: () => import('@/views/home/ClientSupport.vue')
    },
    {
      path: '/applicationMarket',
      name: 'ApplicationMarket',
      meta: {
        title: '应用市场',
        permissions: 'no'
      },
      component: () => import('@/views/applicationMarket/Index.vue')
    },
    {
      path: '/applicationMarketSearch',
      name: 'ApplicationMarketSearch',
      meta: {
        title: '应用市场',
        permissions: 'no'
      },
      component: () => import('@/views/applicationMarket/Search.vue')
    },
    {
      path: '/applicationMarketDetail',
      name: 'ApplicationMarketDetail',
      meta: {
        title: '应用市场',
        permissions: 'no'
      },
      component: () => import('@/views/applicationMarket/Detail.vue')
    },
    {
      path: '/payMent',
      name: 'PayMent',
      meta: {
        title: '待支付',
        permissions: 'no'
      },
      component: () => import('@/views/payMent/Index.vue')
    },
    {
      path: '/payMentDetail',
      name: 'PayMentDetail',
      meta: {
        title: '待支付',
        permissions: 'no'
      },
      component: () => import('@/views/payMent/Detail.vue')
    },
    {
      path: '/caseIndex',
      name: 'CaseIndex',
      meta: {
        title: '成功案例',
        permissions: 'no'
      },
      component: () => import('@/views/successCase/CaseIndex.vue')
    },
    {
      path: '/caseDetail',
      name: 'CaseDetail',
      meta: {
        title: '案例详情',
        permissions: 'no'
      },
      component: () => import('@/views/successCase/CaseDetail.vue')
    },
    {
      path: '/demoTest',
      name: 'DemoTest',
      meta: {
        title: '测试页面',
        permissions: 'no'
      },
      component: () => import('@/views/demoTest/TestIndex.vue')
    },
    {
      path: '/realnameAuth',
      name: 'RealnameAuth',
      meta: {
        title: '实名认证',
        permissions: 'no'
      },
      component: () => import('@/views/authentication/AuthIndex.vue')
    },
    {
      path: '/authInfo',
      name: 'AuthInfo',
      meta: {
        title: '认证信息',
        permissions: 'no'
      },
      component: () => import('@/views/authentication/AuthInfo.vue')
    },
    {
      path: '/authResult',
      name: 'AuthResult',
      meta: {
        title: '认证结果',
        permissions: 'no'
      },
      component: () => import('@/views/authentication/AuthResult.vue')
    }
  ]
}

export const forgetPasswordRoute = {
  path: '/',
  component: SingleLayout,
  children: [
    {
      path: 'forgetPassword',
      name: 'ForgetPassword',
      component: () => import('@/views/login/ForgetPassword.vue')
    }
  ]
}

export const redirectRoute = {
  path: '/redirect',
  name: 'Redirect',
  component: () => import('@/views/redirect/Redirect.vue')
}

// 500
export const serverErrorRoute = {
  path: '/500',
  name: 'ServerError',
  component: () => import('@/views/error/500.vue')
}

// 404
export const notFoundRoute = {
  path: '/:pathMatch(.*)*',
  name: 'NotFound',
  component: () => import('@/views/error/404.vue')
}

// 授权页面
export const authRoute = {
  path: '/authorization',
  name: 'Authorization',
  component: () => import('@/views/login/AuthBody.vue')
}

export const constantRoutes = [
  rootRoute,
  loginRegisterRoute,
  forgetPasswordRoute,
  redirectRoute,
  serverErrorRoute,
  homeRoute,
  authRoute
]
