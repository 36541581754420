<script setup>
  import { ref, watchEffect, watch, computed, toRaw, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useAsyncRoute } from '@/store/modules/asyncRoute'

  const route = useRoute()
  const router = useRouter()
  const asyncRoutes = useAsyncRoute()

  // 判断是否root路由
  const isRootRoute = (routeConf) => {
    return routeConf.meta.isRoot
  }

  /**
   * 获取路由path，
   * 如果路由设置了meta.isRoot，并且有子路由的情况下，
   * path为当前路由的redirect，
   * 其他情况都是用path
   * @param {Object} routeConf 路由配置
   */
  const getRoutePath = (routeConf) => {
    const { path, redirect, name, children = [] } = routeConf
    const isRoot = isRootRoute(routeConf)
    if (isRoot && children.length > 0) {
      if (!redirect) {
        throw Error(`${name}路由设置了isRoot，并且有子路由，则必须设置redirect`)
      }
      return redirect
    }
    return path
  }

  // 过滤出当前路由所属模块的所有路由
  const filterCurrentRouteModules = () => {
    const { meta = {} } = route
    const moduleName = meta.module
    return toRaw(asyncRoutes.getMenus)
      .filter((menu) => {
        return menu.meta && menu.meta.module === moduleName
      })
      .sort((a, b) => {
        const sortA = a.meta ? a.meta.sort : 0
        const sortB = b.meta ? b.meta.sort : 0
        return sortA - sortB
      })
  }

  // 生成侧边栏菜单
  const generatorMenu = (routerMap) => {
    return routerMap
      .filter((routeItem) => routeItem.meta && !routeItem.meta.hidden)
      .map((routeItem) => {
        const { name, meta = {}, children } = routeItem
        const isRoot = isRootRoute(routeItem)
        const currentMenu = {
          ...meta,
          name,
          label: meta.title,
          path: getRoutePath(routeItem),
          key: name
        }

        // 没有设置 meta.isRoot，且有子路由的路由，
        // 递归生成对应的子菜单
        if (!isRoot && children && children.length > 0) {
          currentMenu.children = generatorMenu(children)
        }

        return currentMenu
      })
  }

  // 侧边栏菜单
  // const menus = ref([])
  // const updateMenus = () => {
  //   const currentModuleRoutes = filterCurrentRouteModules()
  //   console.log('currentModuleRoutes', currentModuleRoutes)
  //   menus.value = generatorMenu(currentModuleRoutes)
  //   console.log('menus.value', menus.value)
  // }
  // updateMenus()

  // const menus = ref([
  //   {
  //     key: 1,
  //     icon: '',
  //     label: '买家中心',
  //     children: [
  //       {
  //         key: 1,
  //         label: '订单管理',
  //         name: 'Order',
  //         path: ''
  //       }
  //     ]
  //   }
  // ])

  // watch(
  //   () => route.meta.module,
  //   () => {
  //     updateMenus()
  //   }
  // )

  // 获取卖家/买家中心左侧菜单
  onMounted(async () => {
    if (route.path.includes('/sellerCenter')) {
      await asyncRoutes.getMenusTree('SELLER')
    }
    if (route.path.includes('/buycenter')) {
      await asyncRoutes.getMenusTree('BUYER')
    }
  })

  const selectedKeys = ref([''])
  const openKeys = computed(() => {
    // return [route.matched[0].name]
    return [route.matched[0].path]
  })

  watchEffect(() => {
    const { name, meta } = route
    const { hidden, activeMenu } = meta
    selectedKeys.value = hidden && activeMenu ? [activeMenu] : [name]
  })

  const onChangeMenu = (menu) => {
    // router.push({ name: menu.name })
    router.push({ path: menu.path })
    sessionStorage.removeItem('current') // 切换菜单时清除缓存的页码
    sessionStorage.removeItem('activeKey') // 切换菜单时清除缓存的页码
  }
</script>
<template>
  <a-menu
    v-model:selectedKeys="selectedKeys"
    :open-keys="openKeys"
    theme="light"
    mode="inline"
    class="not-select sidebar-menu"
    :style="{ borderRight: 0 }"
    :inline-indent="24"
  >
    <a-sub-menu v-for="menu in asyncRoutes.menus" :key="menu.path">
      <template #title>
        <span>
          <component :is="menu.css" />
          <span>{{ menu.name }}</span>
        </span>
      </template>
      <a-menu-item
        v-for="childMenu in menu.children"
        :key="childMenu.path"
        @click="onChangeMenu(childMenu)"
      >
        {{ childMenu.name }}
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
  <!-- <a-menu
    v-model:selectedKeys="selectedKeys"
    :open-keys="openKeys"
    theme="light"
    mode="inline"
    class="not-select sidebar-menu"
    :style="{ borderRight: 0 }"
    :inline-indent="24"
  >
    <a-sub-menu v-for="menu in menus" :key="menu.key">
      <template #title>
        <span>
          <component :is="menu.icon" />
          <span>{{ menu.label }}</span>
        </span>
      </template>
      <a-menu-item
        v-for="childMenu in menu.children"
        :key="childMenu.key"
        @click="onChangeMenu(childMenu)"
      >
        {{ childMenu.label }}
      </a-menu-item>
    </a-sub-menu>
  </a-menu> -->
</template>

<style lang="less" scoped>
  .sidebar-menu {
    &.ant-menu {
      &.ant-menu-dark {
        background: transparent;
      }
    }
  }
</style>
