import Baselayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/corporateCulture',
    redirect: '/corporateCulture/CorCulBrand',
    name: 'CorporateCulture',
    meta: {
      title: '企业文化',
      icon: 'GlobalOutlined',
      activeMenu: 'CorporateCulture',
      module: 'corporateCulture',
      permissions: 'ARKqywh'
    },
    component: Baselayout,
    children: [
      {
        path: 'CorCulBrand',
        name: 'CorCulBrand',
        meta: {
          title: '品牌配置'
        },
        component: () => import('@/views/corporateCulture/brand/Index.vue')
      },
      {
        path: 'CorCulSettingLogo',
        name: 'CorCulSettingLogo',
        meta: {
          hidden: true,
          activeMenu: 'CorCulBrand',
          title: '品牌Logo显示配置'
        },
        component: () =>
          import('@/views/corporateCulture/brand/SettingLogo.vue')
      },
      {
        path: 'CorCulSettingBg',
        name: 'CorCulSettingBg',
        meta: {
          hidden: true,
          activeMenu: 'CorCulDispose',
          title: '定制注册/登录界面'
        },
        component: () => import('@/views/corporateCulture/brand/SettingBg.vue')
      },
      {
        path: 'CorCulSettingFooter',
        name: 'CorCulSettingFooter',
        meta: {
          hidden: true,
          activeMenu: 'CorCulBrand',
          title: '定制企业联系方式'
        },
        component: () =>
          import('@/views/corporateCulture/brand/SettingFooter.vue')
      },
      {
        path: 'CorCulDispose',
        name: 'CorCulDispose',
        meta: {
          title: '功能配置'
        },
        component: () => import('@/views/corporateCulture/setting/Index.vue')
      },
      {
        path: 'CorCulTheme',
        name: 'CorCulTheme',
        meta: {
          title: '主题配置'
          // hidden: true,
          // activeMenu: 'CorCulTheme',
        },
        component: () => import('@/views/corporateCulture/theme/Index.vue')
      }
    ]
  }
]

export default routes
