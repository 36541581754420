import Baselayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/notice',
    redirect: '/notice/Message',
    name: 'Notice',
    meta: {
      title: '消息管理',
      icon: 'MailOutlined',
      module: 'notice',
      permissions: 'ARKxxzx'
    },
    component: Baselayout,
    children: [
      {
        path: 'demo',
        name: 'demo',
        meta: {
          title: 'demo',
          hidden: true
        },
        props: true,
        component: () => import('@/views/notice/demo.vue')
      },
      {
        path: 'instation',
        name: 'Instation',
        meta: {
          title: '站内消息'
        },
        props: true,
        component: () => import('@/views/notice/InstationMessage.vue')
      },
      {
        path: 'InstationDetail/:userId/:detailId',
        name: 'InstationDetail',
        props: true,
        meta: {
          title: '消息详情',
          hidden: true,
          activeMenu: 'Instation'
        },
        component: () =>
          import('@/views/notice/components/instation/InstationDetail.vue')
      }
    ]
  }
]

export default routes
