import { defineStore } from 'pinia'
import { ConfigProvider } from 'ant-design-vue'
import { store } from '@/store'
import { Storage } from '@/utils/Storage'
import { THEME_KEY } from '@/utils/constant'
import { getBrandLogo } from '@/apis'

/** 主题色 */
export const defaultConfig = {
  // primaryColor: 'rgb(0, 177, 150)' // '#F5222D', // primary color of ant design
  primaryColor: 'rgb(124, 190, 174)' // '#F5222D', // primary color of ant design
}

export const ThemeState = {
  primaryColor: 'rgb(0, 177, 150)' // '#F5222D', // primary color of ant design
}

// const getLocalThemeConfig = () => {
//   try {
//     return JSON.parse(Storage.get(THEME_KEY, '{}'))
//   } catch {
//     return {}
//   }
// }

// const localThemeConfig = getLocalThemeConfig()
// const { primaryColor } = localThemeConfig
ConfigProvider.config({
  theme: {
    primaryColor:'#2154dc'
  }
})
const changeFavicon = (link) => {
  let $favicon = document.querySelector('link[rel="icon"]')
  if ($favicon !== null) {
    $favicon.href = link
  } else {
    $favicon = document.createElement('link')
    $favicon.rel = 'icon'
    $favicon.href = link
    document.head.appendChild($favicon)
  }
}
export const useThemeStore = defineStore({
  id: 'theme',
  state: () => ({
    ...defaultConfig,
    // ...localThemeConfig,
    brandLogoMini: '',
    brandLogo: ''
  }),
  getters: {
    getNavTheme() {
      return this.navTheme
    },
    getDarkThemeLogo() {
      return this.brandLogo
    },
    getDarkThemeLogoMini() {
      return this.brandLogoMini
    }
  },
  actions: {
    /** antdv自带的改变主题颜色方法，但可以配置的颜色 */
    fillColor(theme) {
      this.primaryColor = theme.primaryColor ?? this.primaryColor
      ConfigProvider.config({
        prefixCls: 'ant',
        iconPrefixCls: 'ant',
        theme
      })
      Storage.set(THEME_KEY, JSON.stringify(theme))
    },
    setColor(theme) {
      this.primaryColor = theme.primaryColor ?? this.primaryColor
    },
    getColor() {
      return JSON.parse(Storage.get(THEME_KEY, '{}'))
    },
    // 设置浏览器icon
    setIcon(icon) {
      changeFavicon(icon)
    },
    async getBrandLogo() {
      try {
        const { brandLogo, brandLogoMini } = await getBrandLogo()
        this.brandLogo = brandLogo
        this.brandLogoMini = brandLogoMini
      } catch (err) {
        //
      }
    }
  }
})

// Need to be used outside the setup
export function useThemeStoreWithOut() {
  return useThemeStore(store)
}
