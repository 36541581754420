<script setup>
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'

  const props = defineProps({
    breadcrumb: {
      type: Boolean,
      default: true
    }
  })

  const route = useRoute()

  /**
   * 生成面包屑
   * @param {Array} matched route.matched
   * @desc 根据路由的 meta.activeMenu 字段匹配兄弟路由的 name，
   * 如果匹配，则两个路由实际为父子路由。
   */
  const generator = (matched) => {
    const routeMap = []
    matched.forEach((item) => {
      const { activeMenu } = item.meta
      const prevRoute = routeMap[routeMap.length - 1]
      if (activeMenu && prevRoute) {
        prevRoute.children = prevRoute.children || []
        const parentRouteIndex = prevRoute.children.findIndex(
          (r) => r.name === activeMenu
        )
        if (parentRouteIndex > -1) {
          const parentRoute = prevRoute.children[parentRouteIndex]
          routeMap.push({
            ...parentRoute,
            label: parentRoute.meta.title,
            key: parentRoute.name,
            disabled: parentRoute.path === '/'
          })
        }
      }
      routeMap.push({
        ...item,
        label: item.meta.title,
        key: item.name,
        disabled: item.path === '/'
      })
    })
    return routeMap
  }

  const breadcrumbList = computed(() => {
    return generator(route.matched)
  })
</script>

<template>
  <a-breadcrumb
    v-if="props.breadcrumb"
    class="bl-breadcrumb"
    style="margin: 16px 0"
  >
    <a-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="item.key">
      <span
        v-if="
          index === breadcrumbList.length - 1 ||
          (item.children && item.children.length)
        "
      >
        {{ item.label }}
      </span>
      <router-link v-else :to="{ name: item.name }">
        {{ item.label }}
      </router-link>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<style></style>
