import Baselayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/environmentConfig',
    redirect: '/environmentConfig/parameter',
    name: 'EnvironmentConfig',
    meta: {
      title: '环境配置',
      icon: 'ControlOutlined',
      activeMenu: 'parameter',
      module: 'environmentConfig'
    },
    component: Baselayout,
    children: [
      {
        path: 'parameter',
        name: 'Parameter',
        meta: {
          title: '系统参数'
        },
        component: () =>
          import('@/views/environmentConfig/parameter/ParameterIndex.vue')
      },
      {
        path: 'addParameter',
        name: 'AddParameter',
        meta: {
          title: '新建自定义系统参数',
          hidden: true,
          activeMenu: 'Parameter'
        },
        component: () =>
          import('@/views/environmentConfig/parameter/AddParameter.vue')
      },
      {
        path: 'parameterDetail',
        name: 'ParameterDetail',
        meta: {
          title: '自定义系统参数详情',
          hidden: true,
          activeMenu: 'Parameter'
        },
        component: () =>
          import('@/views/environmentConfig/parameter/ParameterDetail.vue')
      },
      {
        path: 'builtInParameterDetail',
        name: 'BuiltInParameterDetail',
        meta: {
          title: '内置系统参数详情',
          hidden: true,
          activeMenu: 'Parameter'
        },
        component: () =>
          import(
            '@/views/environmentConfig/parameter/BuiltInParameterDetail.vue'
          )
      },
      {
        path: 'parameterGroup',
        name: 'ParameterGroup',
        meta: {
          title: '参数组管理'
        },
        component: () =>
          import(
            '@/views/environmentConfig/parameterGroup/ParameterGroupIndex.vue'
          )
      },
      // 新增参数组
      {
        path: 'addParameterGroup',
        name: 'AddParameterGroup',
        meta: {
          title: '新建系统自定义参数组',
          hidden: true,
          activeMenu: 'ParameterGroup'
        },
        component: () =>
          import(
            '@/views/environmentConfig/parameterGroup/AddParameterGroup.vue'
          )
      },
      // 参数组详情
      {
        path: 'parameterGroupDetail',
        name: 'ParameterGroupDetail',
        meta: {
          title: '自定义参数组详情',
          hidden: true,
          activeMenu: 'ParameterGroup'
        },
        component: () =>
          import(
            '@/views/environmentConfig/parameterGroup/ParameterGroupDetail.vue'
          )
      },
      {
        path: 'builtInParameterGroupDetail',
        name: 'BuiltInParameterGroupDetail',
        meta: {
          title: '内置参数组详情',
          hidden: true,
          activeMenu: 'ParameterGroup'
        },
        component: () =>
          import(
            '@/views/environmentConfig/parameterGroup/BuiltInParameterGroupDetail.vue'
          )
      }
    ]
  }
]

export default routes
