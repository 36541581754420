import BaseLayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/',
    component: BaseLayout,
    props: { sider: false, breadcrumb: false },
    meta: {
      hidden: true
    },
    children: [
      {
        path: '/applicationMarket',
        name: 'ApplicationMarket',
        meta: {
          title: '应用市场'
        },
        component: () => import('@/views/applicationMarket/Index.vue')
      },
      {
        path: '/applicationMarketSearch',
        name: 'ApplicationMarketSearch',
        meta: {
          title: '应用市场'
        },
        component: () => import('@/views/applicationMarket/Search.vue')
      },
      {
        path: '/applicationMarketDetail',
        name: 'ApplicationMarketDetail',
        meta: {
          title: '应用市场详情'
        },
        component: () => import('@/views/applicationMarket/Detail.vue')
      }
    ]
  }
]

export default routes
