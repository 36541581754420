import Baselayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/organization',
    redirect: '/organization/department',
    name: 'Organization',
    meta: {
      title: '组织架构',
      icon: 'TeamOutlined',
      module: 'unifiedCenter',
      sort: 3,
      permissions: 'ARKzzjg'
    },
    component: Baselayout,
    children: [
      {
        path: 'member',
        meta: {
          hidden: true
        },
        component: () => import('@/views/Organization/OrgMember.vue')
      },
      {
        path: 'department',
        name: 'Department',
        meta: {
          title: '部门管理'
        },
        component: () => import('@/views/Organization/OrgDepartment.vue')
      }
    ]
  }
]

export default routes
