import axios from 'axios'
import { message } from 'ant-design-vue'
import store from 'store'
import { useRouter } from 'vue-router'
import { ACCESS_TOKEN, ERROR_MESSAGE } from '@/utils/constant'

const baseURL = import.meta.env.VITE_API_BASE_URL
const timeout = 6 * 1000

const router = useRouter()

const instance = axios.create({
  baseURL,
  timeout
})

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const newConfig = config
    const token = store.get(ACCESS_TOKEN)
    // newConfig.headers.Authorization = token
    newConfig.headers.hzToken = token
    return newConfig
  },
  (error) => Promise.reject(error)
)

// 通用错误码
// 列表中的的错误码会全局 message 提示，
// 不在列表中的错误码需业务模块自行处理。
const errorCodes = [200460, 200500, 200501, 200506]

// 接口api白名单
const whiteApiList = [
  // 账号管理
  '/hzcloud-platform/hzcloud-admin/manage/buyer/getById',
  '/hzcloud-platform/hzcloud-admin/manage/buyer/update',
  '/hzcloud-platform/hzcloud-admin/app/appProfession/list',
  '/hzcloud-platform/hzcloud-admin/app/appCategory/list',
  '/hzcloud-platform/hzcloud-admin/buyer/buyerAddress/listPage',
  '/hzcloud-platform/hzcloud-admin/buyer/buyerAddress/save',
  '/hzcloud-platform/hzcloud-admin/buyer/buyerAddress/getById',
  '/hzcloud-platform/hzcloud-admin/buyer/buyerAddress/delete',
  '/hzcloud-platform/hzcloud-admin/buyer/buyerAddress/update',
  '/hzcloud-platform/hzcloud-admin/manage/buyer/subAccounts',
  '/hzcloud-platform/hzcloud-admin/buyer-auth/auditJoin',
  '/hzcloud-platform/hzcloud-admin/buyer-auth/setAdmin',
  '/hzcloud-platform/hzcloud-admin/buyer-auth/add-emp',
  '/hzcloud-platform/hzcloud-admin/buyer-auth/rem-emp',
  '/hzcloud-platform/hzcloud-admin/manage/buyer/updatePayment',
  // 卖家中心 - 应用列表
  '/hzcloud-platform/hzcloud-admin/app/list',
  '/hzcloud-platform/hzcloud-admin/app/export',
  '/hzcloud-platform/hzcloud-admin/app/operation',
  '/hzcloud-platform/hzcloud-admin/app/appCategory/listPage',
  '/hzcloud-platform/hzcloud-admin/app/appTag/listPage',
  '/hzcloud-platform/hzcloud-admin/app/appScale/listPage',
  '/hzcloud-platform/hzcloud-admin/app/appProfession/listPage',
  '/hzcloud-platform/hzcloud-admin/app/appScene/listPage',
  // 客服管理
  '/hzcloud-platform/hzcloud-admin/customerService/listPage',
  '/hzcloud-platform/hzcloud-admin/customerService/getById',
  '/hzcloud-platform/hzcloud-admin/customerService/delete',
  '/hzcloud-platform/hzcloud-admin/customerService/save',
  '/hzcloud-platform/hzcloud-admin/customerService/update',
  // 字典
  '/hzcloud-platform/hzcloud-admin/dictionaries/detail/list',
  // 获取买家和卖家左侧菜单栏
  '/hzcloud-platform/hzcloud-admin/system/menu/getTrees'
]

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    const { data } = response
    const { respCode, respMsg, datas } = data || {}
    const requestUrl = response.config.url
    if (respCode !== 200) {
      // 接口返回错误
      // if (errorCodes.includes(respCode)) {
      //   message.destroy()
      //   message.error(respMsg)
      // }
      if (respCode === 999) {
        router.replace('/login')
      }
      if (whiteApiList.includes(requestUrl)) {
        return data
      }
      message.destroy()
      message.error(respMsg)
      return Promise.reject(data)
    }
    if (whiteApiList.includes(requestUrl)) {
      return data
    }
    return datas
  },
  (error) => {
    const { response } = error
    const { status } = response || {}

    // 401 跳登录页
    if (status === 401 || status === 999) {
      // 清除本地存储信息
      store.clearAll()

      // 跳转登录页
      window.location.href = `${window.location.origin}/#/login`
    }

    // 通用 http 异常状态码，统一提示
    if (ERROR_MESSAGE[status]) {
      message.destroy()
      message.error(ERROR_MESSAGE[status])
    }
    return Promise.reject(error)
  }
)

export default instance
