import 'virtual:svg-icons-register' // eslint-disable-line
import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import useAntdIcon from '@/assets/icon/antd'
import dayjs from 'dayjs'
// import store from 'store'
import { setupRouter } from '@/router'
import { setupStore } from '@/store'
import { setupDirectives } from '@/directives'
// import { ACCESS_TOKEN } from '@/utils/constant'
import '@/assets/less/index.less'
import SvgIcon from '@/components/SvgIcon.vue'
import App from './App.vue'

// 设置 dayjs 当前语言为中文
dayjs.locale('zh-cn')

// if (process.env.NODE_ENV === 'development') {
//   const token = import.meta.env.VITE_TOKEN
//   store.set(ACCESS_TOKEN, token)J
// }

// 判断当前环境
window.isdev = process.env.NODE_ENV === 'development'

const app = createApp(App)

// 注册全局 Ant Design Icon
useAntdIcon(app)

setupStore(app)
setupRouter(app)
setupDirectives(app)

// 注册全局组建
app.component('SvgIcon', SvgIcon)

app.use(Antd)

app.mount('#app')
