import Baselayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/buycenter',
    redirect: '/buycenter',
    name: 'Buycenter',
    meta: {
      title: '买家中心',
      icon: 'AppstoreOutlined',
      module: 'buycenter',
      permissions: 'ARKyygl'
    },
    component: Baselayout,
    children: [
      {
        // 买家中心
        path: '',
        name: 'buyIndex',
        meta: {
          title: ''
        },
        component: () => import('@/views/buyCenter/buyIndex.vue')
      },
      {
        // 我的订单
        path: 'order',
        name: 'Order',
        meta: {
          title: '我的订单',
          hidden: true,
          activeMenu: '/buycenter/order'
        },
        component: () => import('@/views/buyCenter/myOrder.vue')
      },
      {
        // 订单详情
        path: 'detail',
        name: 'OrderDetail',
        meta: {
          title: '订单详情'
        },
        component: () => import('@/views/buyCenter/orderDetail.vue')
      },
      {
        // 已购买的产品
        path: 'purchased',
        name: 'PurchasedGoods',
        meta: {
          title: '已购买的产品'
        },
        component: () => import('@/views/buyCenter/purchasedGoods.vue')
      },
      {
        path: 'userManage',
        name: 'UserManageIndex',
        meta: {
          title: '账号管理',
          hidden: true,
          activeMenu: '/buycenter/userManage'
        },
        component: () => import('@/views/userManage/index.vue')
      },
      {
        // 发票管理
        path: 'invoice',
        name: 'Invoice',
        meta: {
          title: '发票管理'
        },
        component: () => import('@/views/invoice/invoiceIndex.vue')
      },
      {
        // 确认开票信息
        path: 'invoiceInfo',
        name: 'InvoiceInfo',
        meta: {
          title: '确认开票信息'
        },
        component: () => import('@/views/invoice/invoiceInfo.vue')
      },
      {
        // 发票详情
        path: 'invoiceDetail',
        name: 'InvoiceDetail',
        meta: {
          title: '发票详情'
        },
        component: () => import('@/views/invoice/components/InvoiceDetail.vue')
      },
      {
        // 发票信息编辑
        path: 'editInvoice',
        name: 'EditInvoice',
        meta: {
          title: '发票信息编辑'
        },
        component: () => import('@/views/invoice/components/EditInvoice.vue')
      }
    ]
  }
]

export default routes
