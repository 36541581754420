/** 动态路由（一般都是需要权限的路由） */
const modules = import.meta.globEager('./modules/**/*.js')

export const asyncRoutes = []

Object.keys(modules).forEach((key) => {
  const mod = modules[key].default || {}
  const modList = Array.isArray(mod) ? [...mod] : [mod]
  asyncRoutes.push(...modList)
})

export default { asyncRoutes }
