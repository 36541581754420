<script setup>
  import zhCN from 'ant-design-vue/es/locale/zh_CN'
  import { useThemeStore } from '@/store/modules/projectConfig'
  import { ACCESS_TOKEN } from '@/utils/constant'
  import {
    getWebLoginConfig,
    getTheme,
    getBrandLogo
  } from '@/apis/modules/corporateCulture'
  import store from 'store'
  import { onMounted } from 'vue'

  const themeStore = useThemeStore()
  // 获取登录前的主题色
  const getConfigData = () => {
    getWebLoginConfig().then((res) => {
      themeStore.fillColor({ primaryColor: res.themeColor || '#00b196' })
    })
  }
  // 获取登录后的主题色
  const getCurrentTheme = () => {
    getTheme().then((res) => {
      themeStore.fillColor({ primaryColor: res.themeColor })
    })
  }
  // 获取登录后的浏览器icon
  const getIcon = () => {
    getBrandLogo().then((res) => {
      themeStore.setIcon(res.brandLogoMini) // 设置浏览器icon
    })
  }
  onMounted(() => {
    // 设置主题色 未登录时
    if (!store.get(ACCESS_TOKEN)) {
      // getConfigData()
    } else {
      // 登录后
      // getCurrentTheme()
      // getIcon()
    }
  })
</script>

<template>
  <a-config-provider :locale="zhCN">
    <router-view></router-view>
  </a-config-provider>
</template>
