<template>
  <svg class="svg-icon" aria-hidden="true" :style="style">
    <use :href="symbolId" :fill="props.color" />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'

  const props = defineProps({
    prefix: {
      type: String,
      default: 'icon'
    },
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#333'
    },
    size: {
      type: [String, Number],
      default: '20px'
    }
  })

  const symbolId = computed(() => `#${props.prefix}-${props.name}`)

  const style = computed(() => {
    const size = typeof props.size === 'string' ? props.size : `${props.size}px`
    return {
      width: size,
      height: size
    }
  })
</script>

<style lang="less">
  .svg-icon {
    vertical-align: middle;
  }
</style>
