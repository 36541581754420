<script setup>
  import { ref, inject } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useUserStore } from '@/store/modules/user'
  import { useNoticeStore } from '@/store/modules/notice'
  import { useThemeStore } from '@/store/modules/projectConfig'
  import { postMessageSign, validSellerJoin } from '@/apis'
  // import defaultLogo from '@/assets/images/logo@2x.b.png'
  import defaultLogo from '@/assets/images/hz_logo.png'
  import defaultAvatar from '@/assets/images/avatar.png'

  import { useAsyncRoute } from '@/store/modules/asyncRoute'

  import NavMenu from './NavMenu.vue'

  const asyncStore = useAsyncRoute()

  const userStore = useUserStore()
  const router = useRouter()
  const route = useRoute()
  const theme = useThemeStore()
  const visible = ref(false)
  const isActive = ref(true)

  const reloadApp = inject('reloadApp')

  // 消息中心
  const noticeStore = useNoticeStore()
  // 默认每30秒获取一次消息数量
  noticeStore.setInter()

  // const onLogout =  () => {
  //    Modal.confirm({
  //     title: '您确定要退出登录吗？',
  //     icon: createVNode(ExclamationCircleOutlined),
  //     closable:true,
  //      onOk() {
  //       // try {
  //       //   await userStore.logout()
  //       //   // 清除消息接口定时器
  //       //   noticeStore.clearInter()
  //       //   sessionStorage.clear()
  //       //   router.replace({ name: 'Login' })
  //       //   console.log('点击确认')

  //       // } catch (error) {}
  //       console.log('点击确认')
  //       // modal.destroy();
  //     },
  //     onCancel() {
  //       // modal.destroy();
  //       console.log('点击取消')
  //     }
  //   })
  // }
  // 登出
  const onLogout = () => {
    visible.value = true
  }

  // 买家中心
  const gotoBuyCenter = async () => {
    isActive.value = false
    await asyncStore.getMenusTree('BUYER')
    router.push('/buycenter')
  }

  const handleAuth = (state) => {
    if (state === 0) {
      // 已加入公司/加入企业审核中不能进行认证
      if (
        userStore.info.accountState !== 8 &&
        userStore.info.accountState !== 7
      ) {
        router.push('/realnameAuth')
      }
    } else {
      router.push({
        path: '/authResult',
        query: {
          // eslint-disable-next-line no-nested-ternary
          type: [1, 2, 3].includes(state)
            ? 'person'
            : [4, 5, 6].includes(state)
            ? 'company'
            : 'join'
        }
      })
    }
  }

  const handleUser = () => {
    if (userStore.info.businessState === 2) {
      router.push('/sellerCenter/userManage')
    } else {
      router.push('/buycenter/userManage')
    }
  }

  // 去到卖家中心
  const gotoConPanel = async () => {
    console.log(2)
    isActive.value = false
    try {
      const data = await validSellerJoin(userStore.info.id)
      if (data.accountStatus === 0) {
        router.push({ path: '/sellerCenter/applyJoin' })
      } else if (data.accountStatus === 3) {
        // sessionStorage.clear()
        // router.push({ name: 'ConPanel' })
        // router.push('/payMent')
        await asyncStore.getMenusTree('SELLER')
        router.push({ path: '/sellerCenter/sellerIndex' })
      } else {
        router.push({ path: '/sellerCenter/applyProgress' })
      }
    } catch (error) {
      //
    }
  }

  // 消息中心
  const getMessageList = async () => {
    const data = await noticeStore.getMessage()
    noticeStore.count = data.totalNum
    noticeStore.messageList = data.datas
  }
  getMessageList()

  // 标记为已读
  const handleRead = (detailIdList) => {
    postMessageSign({
      detailIdList,
      userId: window.isdev ? '1572045612268199940' : userStore.info.userId
    })
      .then(() => {
        getMessageList()
      })
      .catch(() => {})
  }

  // 去到消息详情
  const handleMessageDetail = (record) => {
    if (record.status === 1) {
      handleRead([record.detailId])
    }
    router.push({
      name: 'InstationDetail',
      params: { userId: userStore.info.userId, detailId: record.detailId }
    })
  }
  // 取消退出
  const handleCancel = () => {
    visible.value = false
  }
  // 确认退出
  const handleConfirm = async () => {
    await userStore.logout()
    //   // 清除消息接口定时器
    noticeStore.clearInter()
    sessionStorage.clear()
    router.replace({ name: 'Login' })
    handleCancel()
  }

  // 查看更多
  const gotoMessageMore = () => {
    if (route.name !== 'Instation') {
      router.push({ name: 'Instation' })
    } else {
      reloadApp()
    }
    sessionStorage.removeItem('current') // 移除缓存的页码
  }

  // 1 - 实名认证审核中
  // 2 - 已完成个人认证
  // 3 - 实名认证失败
  // 4 - 企业认证审核中
  // 5 - 已完成企业认证
  // 6 - 企业认证失败
  // 7 - 加入企业审核中
  // 8 - 加入企业成功
  // 9 - 加入企业失败
  const getColor = (state) => {
    switch (state) {
      case 0:
        return 'default'
      case 1:
      case 4:
      case 7:
        return 'processing'
      case 3:
      case 6:
      case 9:
        return 'error'
      case 2:
      case 5:
      case 8:
        return 'success'
      default:
        return ''
    }
  }
</script>

<template>
  <div class="layout-header">
    <div class="layout-header-left">
      <div class="logo not-select" @click="router.push({ name: 'HomeIndex' })">
        <img :src="theme.getDarkThemeLogo || defaultLogo" alt="LOGO" />
      </div>
    </div>
    <div class="layout-header-center">
      <NavMenu :hide-active="isActive" @update="isActive = true" />
    </div>
    <div v-if="userStore.token" class="layout-header-right">
      <div
        v-if="userStore.info.businessState !== 2"
        class="lhr-conPanel"
        @click="gotoBuyCenter"
      >
        <p
          href="javascript:;"
          :class="route.name === 'ConPanel' ? 'primary-color' : ''"
        >
          买家中心
        </p>
      </div>
      <div class="lhr-conPanel" @click="gotoConPanel">
        <p
          href="javascript:;"
          :class="/^\/sellerCenter/.test(route.path) ? 'primary-color' : ''"
        >
          卖家中心
        </p>
      </div>

      <div v-permission="'ARKxxzx'" class="lhr-notice">
        <a-dropdown>
          <div
            class="lhr-icon"
            :class="route.name === 'Instation' ? 'primary-color' : ''"
            @click="gotoMessageMore"
          >
            <BellOutlined />
            <span v-show="noticeStore.count" class="lhr-status">
              {{ noticeStore.count <= 99 ? noticeStore.count : '99+' }}
            </span>
          </div>
          <template #overlay>
            <a-menu>
              <p class="lhr-notice-title">消息中心</p>
              <a-menu-item
                v-for="item in noticeStore.messageList"
                :key="item.detailId"
                @click="handleMessageDetail(item)"
              >
                <div class="lhr-notice-item">
                  <span
                    class="lhrn-i-status"
                    :style="{
                      backgroundColor:
                        item.status === 1 ? '#05626e' : 'transparent',
                      border: item.status === 2 ? '1px solid #999' : 'none'
                    }"
                  ></span>
                  <p :title="item.title">{{ item.title }}</p>
                </div>
                <p class="lhr-p-createTime" :title="item.createTime">
                  {{ item.createTime }}
                </p>
                <a-divider :dashed="true" style="margin: 10px 0 0" />
              </a-menu-item>
              <a
                class="lhr-notice-more"
                title="去往消息中心，查看更多！"
                @click="gotoMessageMore"
              >
                查看更多
              </a>
            </a-menu>
          </template>
        </a-dropdown>
      </div>

      <a-dropdown>
        <div class="lhr-user">
          <div class="lhr-avatar">
            <img
              v-if="userStore.getAvatar"
              :src="userStore.getAvatar"
              alt="头像"
            />
            <img v-else :src="defaultAvatar" alt="头像" />
          </div>
          <span class="username">{{ userStore.info.mobile }}</span>
          <DownOutlined />
        </div>
        <template #overlay>
          <a-menu class="lhr-menu">
            <a-menu-item>
              <div class="lhr-menu-item">
                <span>账号ID</span>
                <span class="blue">{{ userStore.info.id }}</span>
              </div>
            </a-menu-item>
            <a-menu-item>
              <div class="lhr-menu-item">
                <span>账号信息</span>
                <a-tag
                  v-if="userStore.info.isAuth === 0"
                  @click="handleAuth(0)"
                >
                  未实名认证
                </a-tag>
                <a-tag
                  v-else
                  :color="getColor(userStore.info.accountState)"
                  @click="handleAuth(userStore.info.accountState)"
                >
                  {{ userStore.info.accountStateName }}
                </a-tag>
              </div>
              <div
                v-if="userStore.info.isAuth === 0"
                class="lhr-menu-item"
                style="margin-top: 10px"
              >
                <span></span>
                <a-tag
                  :color="getColor(userStore.info.accountState)"
                  @click="handleAuth(userStore.info.accountState)"
                >
                  {{
                    userStore.info.accountStateName.length > 14
                      ? userStore.info.accountStateName.slice(0, 14) + '...'
                      : userStore.info.accountStateName
                  }}
                </a-tag>
              </div>
            </a-menu-item>
            <a-menu-item>
              <div class="lhr-menu-item" @click="handleUser">
                <span>账号管理</span>
                <RightOutlined style="color: #c8c9cc" />
              </div>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="onLogout"> 退出当前账号</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <div v-else class="layout-header-right">
      <!-- <span class="btn-login" @click="router.push({ name: 'Login' })">
        登录
      </span>
      <span class="btn-register" @click="router.push({ name: 'Register' })">
        注册
      </span> -->
      <a-button
        size="middle"
        class="login-btn"
        @click="router.push({ name: 'Login' })"
      >
        登录
      </a-button>
      <a-button
        type="primary"
        size="middle"
        @click="router.push({ name: 'Register' })"
      >
        注册
      </a-button>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    :closable="false"
    class="logout-modal"
    :width="400"
    @cancel="handleCancel"
  >
    <!-- <template #title> 温馨提示</template> -->
    <div>
      <ExclamationCircleFilled style="color: rgb(255, 153, 0)" />
      您确定要退出登录吗？
    </div>
    <template #footer>
      <a-button @click="handleCancel"> 取消 </a-button>
      <a-button type="primary" @click="handleConfirm"> 确认 </a-button>
    </template>
  </a-modal>
</template>

<style lang="less" scoped>
  :deep(.ant-menu-horizontal) {
    border: none;
  }
  .blue {
    color: #0052d9;
  }
  .layout-header {
    display: flex;
    justify-content: space-between;

    .layout-header-left {
      flex: 0 0 200px;
      padding: 10px 35px;
      .logo {
        color: #fff;
        height: 100%;
        font-size: 22px;
        line-height: 1;
        letter-spacing: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 130px;
        height: 38px;
        img {
          // margin-right: 10px;
          height: 38px;
          width: 130px;
        }
      }
    }

    .layout-header-center {
      flex: 1;
      transition: all 0.5s;
    }

    .layout-header-right {
      padding: 0 24px;
      display: flex;
      gap: 32px;
      align-items: center;
      justify-content: space-between;
      color: #333;
      :deep(.ant-btn) {
        width: 72px;
        height: 32px;
      }
      .login-btn {
        background-color: #e7f6f3;
        color: #48baa0;
        border: none;
      }
      .lhr-user {
        display: flex;
        height: 100%;
        align-items: center;
        cursor: pointer;

        .lhr-avatar {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          overflow: hidden;
          img {
            width: 120px;
            height: 100%;
            vertical-align: top;
          }
        }

        .username {
          max-width: 10em;
          margin: 0 5px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      // 控制台
      .lhr-conPanel {
        cursor: pointer;
        &:hover {
          color: @primary-color;
        }
      }
      // 消息中心
      .lhr-notice {
        display: flex;
        .lhr-icon {
          cursor: pointer;
          line-height: 70px;
        }
        .lhr-status {
          display: inline-block;
          text-align: center;
          font-size: 12px !important;
          // width: 26px;
          height: 18px;
          line-height: 19px;
          padding: 0 5px;
          background-color: #44aa94;
          color: #fff;
          // border: 1px solid #fff;
          border-radius: 4px;
          position: relative;
          top: -12px;
          right: 0px;
          user-select: none;
          overflow: hidden;
        }
        .ant-dropdown-trigger {
          font-size: 20px;
          &:hover {
            color: @primary-color;
          }
        }
      }

      .btn-login,
      .btn-register {
        cursor: pointer;
      }
    }
  }
  .lhr-menu {
    width: 216px;
    background: linear-gradient(180deg, #f3f5f8 0%, #ffffff 100%);
    .lhr-menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      color: #4a5770;
      line-height: 20px;
    }
    :deep(.ant-tag) {
      margin-right: 0 !important;
      color: #98a3b7;
      background: #ffffff;
    }
    :deep(.ant-dropdown-menu-item) {
      height: 44px;
      &:nth-child(2) {
        height: auto;
        min-height: 44px;
        max-height: 60px;
      }
      &:nth-child(3) {
        margin-bottom: 10px;
      }
      &:last-child {
        background: linear-gradient(180deg, #f3f5f8 0%, #ffffff 100%);
      }
    }
    :deep(.ant-tag-processing) {
      color: #0052d9;
      border: 1px solid #0052d9;
    }
    :deep(.ant-tag-error) {
      color: #e86452;
      border: 1px solid #e86452;
    }
    :deep(.ant-tag-success) {
      color: #5ad8a6;
      border: 1px solid #5ad8a6;
    }
  }

  :deep(li.lhr-dropdown-item) {
    padding: 8px 16px;
    a {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  // 消息中心
  :deep(.lhr-notice-title) {
    padding: 12px 33px;
    font-weight: 600;
    border-bottom: 1px solid #eaeaea;
  }
  :deep(.lhr-notice-more) {
    display: inline-block;
    padding: 10px 32px;
  }
  :deep(.lhr-notice-item) {
    display: flex;
    align-items: center;
    .lhrn-i-status {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
  :deep(.lhr-p-createTime) {
    padding-left: 15px;
    color: #999;
  }
  :deep(.ant-dropdown-menu-item) {
    padding: 5px 16px;
    p {
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.6;
    }
    p:hover {
      text-decoration: underline;
    }
  }
</style>
<style lang="less">
  .logout-modal {
    .ant-modal-body {
      font-size: 16px !important;
    }
    .ant-modal-footer {
      border: none !important;
    }
  }
</style>
