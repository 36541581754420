import request from '@/utils/request'
// 获取品牌logo
export const getBrandLogo = () =>
  request.get(`/ark-admin/api/v1/culture/brandLogo`)
// 设置品牌logo
export const setBrandLogo = (data) =>
  request.put(`/ark-admin/api/v1/culture/brandLogo`, data)
// 获取企业页脚信息
export const getFooter = () => request.get(`/ark-admin/api/v1/culture/footer`)
// 更新企业页脚信息
export const setFooter = (data) =>
  request.put(`/ark-admin/api/v1/culture/footer`, data)
// 获取企业登录背景图
export const getLoginConfig = () => request.get(`/ark-admin/api/v1/loginConfig`)
// 获取企业登录背景图
export const setLoginConfig = (data) =>
  // request.put(`/ark-admin/api/v1/culture/loginConfig`,data)
  request.put(`/ark-admin/api/v1/loginConfig`, data)
// 获取主题
export const getThemes = () => request.get(`/ark-admin/api/v1/themes`)
// 获取主题
export const getTheme = () => request.get(`/ark-admin/api/v1/culture/theme`)
// 设置主题
export const setTheme = (data) =>
  request.put(`/ark-admin/api/v1/culture/theme`, data)
// 获取未登录之前配置信息
export const getWebLoginConfig = () =>
  request.get(`/ark-admin/api/v1/loginConfig/web`)
