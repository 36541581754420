/**
 * 首页数据接口
 */

import request from '@/utils/request'

/**
 * 用户信息
 */
export const getUserInfo = (params) => {
  return request.post('/hzcloud-platform/hzcloud-admin/buyer/info', params)
}

/**
 * 菜单列表
 */
export const getHomeMenu = () => {
  return request.get('/ark-admin/api/v1/index/menu')
}

/**
 * Banner
 */
export const getHomeBanner = () => {
  return request.get('/ark-admin/api/v1/index/banner')
}

/**
 * 组织数据
 */
export const getHomeOrgData = () => {
  return request.get('/ark-admin/api/v1/index/stats')
}

/**
 * 应用管理列表
 */
export const getHomeAppList = () => {
  return request.get('/ark-admin/api/v1/index/application')
}

/**
 * 平台列表
 */
export const getHomePlatform = () => {
  return request.get('/ark-admin/api/v1/index/platform')
}

/**
 * 统一中心
 */
export const getHomeUnifiedCenter = () => {
  return request.get('/ark-admin/api/v1/index/unitedCenter')
}

/**
 * 获取子应用地址
 */
export const getChildAppUrl = (clientId) => {
  return request.get(`/ark-admin/api/v1/oauth2/authorize?clientId=${clientId}`)
}

/**
 * 获取首页数据
 */
export const getHomeData = () => {
  return request.get(`/hzcloud-platform/hzcloud-admin/buyer/index/initData`)
}

/**
 * 获取首页模块详情数据
 */
export const getHomeModuleDetails = (clientId) => {
  return request.get(
    `/hzcloud-platform/hzcloud-admin/buyer/index/client/${clientId}/desc`
  )
}

/**
 * 客户支持
 */
export const getCustomerSupport = (params) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/buyer/customerSupport',
    params
  )
}

/**
 *  华制云 - 获取卖家中心和买家中心菜单
 */
export const getMenusTree = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/system/menu/getTrees',
    data
  )
}
