import Baselayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/application',
    redirect: '/application/applicationList',
    name: 'Application',
    meta: {
      title: '应用管理',
      icon: 'AppstoreOutlined',
      module: 'application',
      permissions: 'ARKyygl'
    },
    component: Baselayout,
    children: [
      {
        // 应用列表
        path: 'applicationList',
        name: 'ApplicationList',
        meta: {
          title: '应用管理'
        },
        component: () => import('@/views/application/ApplicationList.vue')
      },
      {
        // 新增应用
        path: 'addApplication',
        name: 'AddApplication',
        meta: {
          title: '新增应用',
          hidden: true,
          activeMenu: 'ApplicationList'
        },
        component: () => import('@/views/application/AddApplication.vue')
      },
      {
        // 编辑应用
        path: 'updateApplication',
        name: 'UpdateApplication',
        meta: {
          title: '编辑应用',
          hidden: true,
          activeMenu: 'ApplicationList'
        },
        component: () => import('@/views/application/AddApplication.vue')
      },
      {
        // 应用详情
        path: 'applicationDetail',
        name: 'ApplicationDetail',
        meta: {
          title: '应用详情',
          hidden: true,
          activeMenu: 'ApplicationList'
        },
        component: () => import('@/views/application/ApplicationDetail.vue')
      }
    ]
  }
]

export default routes
