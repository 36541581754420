import { defineStore } from 'pinia'
import store from 'store'
import { ACCESS_TOKEN, CURRENT_USER } from '@/utils/constant'
import {
  loginAccount,
  loginMobile,
  loginWecom,
  getUserInfo,
  logout
} from '@/apis'
import { ConsoleSqlOutlined } from '@ant-design/icons-vue'
import jsonEditorVue from 'json-editor-vue3/lib/json-editor.vue'

export const useUserStore = defineStore({
  id: 'appUser',
  state: () => ({
    token: store.get(ACCESS_TOKEN),
    username: '',
    avatar: '',
    permissions: [],
    info: store.get(CURRENT_USER) || {}
  }),
  getters: {
    getToken() {
      return this.token
    },
    getUsername() {
      return this.username
    },
    getAvatar() {
      return this.avatar
    },
    getPermissions() {
      return this.permissions
    },
    getUserInfo() {
      return this.info
    }
  },
  actions: {
    setToken(token) {
      this.token = token
    },
    setUsername(username) {
      this.username = username
    },
    setAvatar(avatar) {
      this.avatar = avatar
    },
    setPermissions(permissions) {
      this.permissions = permissions
    },
    setUserInfo(info) {
      this.info = info
    },
    async loginAccount(params) {
      const data = await loginAccount(params)
      const { access_token: token } = data
      store.set(ACCESS_TOKEN, token)
      this.setToken(token)
    },
    async loginMobile(params) {
      const data = await loginMobile(params)
      const { tokenValue, loginId } = data
      store.set(ACCESS_TOKEN, tokenValue)
      store.set('loginId', loginId)
      this.setToken(tokenValue)
      this.getInfo(loginId)
    },
    async registerMobile(data) {
      const { tokenValue, loginId } = data
      store.set(ACCESS_TOKEN, tokenValue)
      store.set('loginId', loginId)
      this.setToken(tokenValue)
      this.getInfo(loginId)
    },
    async loginWecom(params) {
      const data = await loginWecom(params)
      const { access_token: token } = data
      store.set(ACCESS_TOKEN, token)
      this.setToken(token)
    },
    //     async getInfo() {
    //   try {
    //     const { sysUser } = await getUserInfo()
    //     store.set(CURRENT_USER, sysUser)
    //     this.setUsername(sysUser.username)
    //     this.setAvatar(sysUser.avatar)
    //     this.setUserInfo(sysUser)
    //     return sysUser
    //   } catch (error) {
    //     //
    //   }
    //   return {}
    // },
    async getInfo(loginId) {
      try {
        const loginInfo = loginId ? JSON.parse(loginId) : ''
        const data = await getUserInfo({ id: loginInfo.id })
        // console.log(loginInfo.id,'打印id')
        store.set(CURRENT_USER, data)
        this.setUsername(data.mobile)
        // this.setAvatar(sysUser.avatar)
        this.setUserInfo(data)
        return data
      } catch (error) {
        //
      }
      return {}
    },
    reset() {
      store.clearAll()
      // store.remove('ACCESS-TOKEN')
      // store.remove('CURRENT-USER')
      this.setToken('')
      this.setUsername('')
      this.setAvatar('')
      this.setPermissions([])
      this.setUserInfo({})
    },
    async logout() {
      try {
        await logout()
      } finally {
        this.reset()
      }
    }
  }
})

export default { useUserStore }
