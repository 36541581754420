import BaseLayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/app',
    component: BaseLayout,
    props: { sider: false, breadcrumb: false },
    meta: {
      hidden: true
    },
    children: [
      {
        path: '/app/:clientId',
        name: 'ChildApp',
        props: true,
        component: () => import('@/views/childApp/ChildApp.vue')
      }
    ]
  }
]

export default routes
