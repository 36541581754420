import { defineStore } from 'pinia'
import { asyncRoutes } from '@/router/asyncRoutes'
import { constantRoutes } from '@/router/constantRoutes'
import { getMenusTree, getPermissionMenu } from '@/apis'

export const useAsyncRoute = defineStore({
  id: 'appAsyncRoute',
  state: () => ({
    menus: [],
    routers: constantRoutes,
    addRouters: [],
    asyncRouteAdded: false
  }),
  getters: {
    getMenus() {
      return this.menus
    },
    getAsyncRoutes() {
      return this.addRouters
    },
    isAsyncRouteAdded() {
      return this.asyncRouteAdded
    }
  },
  actions: {
    setMenus(menus) {
      this.menus = menus
    },
    setRouters(routers) {
      this.addRouters = routers
      this.routers = constantRoutes.concat(routers)
    },
    setAsyncRouteAdded(added) {
      this.asyncRouteAdded = added
    },
    // async generateRoutes() {
    //   const permissionsMap = await getPermissionMenu()

    //   // 通过后端权限表过滤出异步路由
    //   // 如果路由 meta.permissions 指定了权限 code，
    //   // 那么必须在后端路由表中存在，证明此用户拥有此路由权限。
    //   // 如果路由没有指定 meta.permissions ，
    //   // 则认为此路由只需要登录即可访问。
    //   const asyncPermissionRoutes = asyncRoutes.filter((route) => {
    //     const { permissions } = route.meta
    //     const hasPermissions = permissionsMap.some(
    //       (p) => p.permissionCode === permissions
    //     )
    //     return !permissions || hasPermissions
    //   })

    //   this.setRouters(asyncPermissionRoutes)
    //   this.setMenus(asyncPermissionRoutes)
    //   return asyncPermissionRoutes
    // },
    async getMenusTree(systemType) {
      // BUYER-买家段端SELLER-买家端
      await getMenusTree({ systemType })
        .then((res) => {
          this.menus = res.datas
        })
        .catch((err) => {
          console.log('获取菜单失败', err)
        })
    }
  }
})

export default { useAsyncRoute }
