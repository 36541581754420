import BaseLayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/account',
    redirect: '/account/info',
    name: 'AccountCenter',
    component: BaseLayout,
    meta: {
      title: '账号管理',
      icon: 'UserOutlined',
      module: 'accountCenter'
    },
    children: [
      {
        path: 'info',
        name: 'AccountInfo',
        meta: {
          title: '账号信息'
        },
        component: () => import('@/views/account/AccountInfo.vue')
      },
      {
        path: 'info/phoneNumber/change',
        name: 'ChangePhoneNumber',
        props: { type: 'change' },
        meta: {
          title: '修改手机',
          hidden: true,
          activeMenu: 'AccountInfo'
        },
        component: () => import('@/views/account/PhoneNumber.vue')
      },
      {
        path: 'info/phoneNumber/bind',
        name: 'BindPhoneNumber',
        props: { type: 'bind' },
        meta: {
          title: '绑定手机',
          hidden: true,
          activeMenu: 'AccountInfo'
        },
        component: () => import('@/views/account/PhoneNumber.vue')
      },
      {
        path: 'updatePassword',
        name: 'UpdatePassword',
        props: true,
        meta: {
          title: '修改密码',
          hidden: true,
          activeMenu: 'AccountInfo'
        },
        component: () => import('@/views/account/UpdatePassword.vue')
      }
    ]
  }
]

export default routes
