import { defineStore } from 'pinia'
import { getMessageFresh } from '@/apis'
import { useUserStore } from './user'

export const useNoticeStore = defineStore('appNotice', {
  state: () => ({
    count: 0,
    messageList: [],
    timer: null
  }),
  getters: {
    userInfo() {
      return useUserStore().info
    }
  },
  actions: {
    getMessage() {
      return new Promise((resolve, reject) => {
        if (this.userInfo.userId) {
          getMessageFresh({
            userId: this.userInfo.userId
          })
            .then((res) => {
              resolve(res)
            })
            .catch(() => {
              this.clearInter()
            })
        }
      })
    },
    setInter(msec = 1000 * 30) {
      this.timer = setInterval(async () => {
        if (this.userInfo.userId) {
          const data = await this.getMessage()
          // 第三方应用
          if (data.totalNum === -1) {
            this.clearInter()
            return false
          }
          this.count = data.totalNum
          this.messageList = data.datas
        } else {
          // 超时登出，清除定时器
          this.clearInter()
        }
      }, msec)
    },
    clearInter() {
      clearInterval(this.timer)
    }
  }
})

export default { useNoticeStore }
