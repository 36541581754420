import { permission } from './modules/permission'

export function setupDirectives(app) {
  // 权限控制指令
  app.directive('permission', permission)
}

export default {
  setupDirectives
}
