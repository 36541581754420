export const ACCESS_TOKEN = 'ACCESS-TOKEN'

export const CURRENT_USER = 'CURRENT-USER'

export const ERROR_MESSAGE = {
  400: '请求参数错误！',
  401: '登录已过期！',
  403: '拒绝访问！',
  404: '请求不存在！',
  500: '服务器错误！',
  503: '服务器正忙！',
  504: '连接超时！'
}

/** 主题色 */
export const THEME_KEY = 'THEME__'

export const PERMISSION_CODE = {}

// 读取环境配置前缀
export const PREFIX_URL = `${
  import.meta.env.VITE_API_BASE_URL
}hzcloud-platform/hzcloud-admin/system/file/previewImage?fileName=`
