<template>
  <a-layout class="base-layout">
    <!-- Header -->
    <a-layout-header
      :class="
        route.name === 'HomeIndex' || route.name === 'PlatDetail'
          ? 'bl-index'
          : 'bl-header'
      "
    >
      <PageHeader />
    </a-layout-header>

    <a-layout class="bl-body">
      <!-- 侧边栏 -->
      <a-layout-sider
        v-if="props.sider"
        v-model:collapsed="collapsed"
        class="bl-sider"
        width="200"
        :collapsed-width="60"
        collapsible
        theme="light"
      >
        <!-- <div class="bl-s-title">
          <SettingOutlined class="icon" />
          <h2 v-if="!collapsed" class="text">{{ moduleName }}</h2>
        </div> -->
        <SiderMenu />
      </a-layout-sider>

      <a-layout class="bl-content" :style="contentStyle">
        <!-- 面包屑 -->
        <Breadcumb
          v-if="props.breadcrumb"
          class="bl-breadcrumb"
          style="margin: 16px 0"
          :breadcrumb="props.breadcrumb"
        />

        <!-- 主体视图 -->
        <a-layout-content class="bl-c-view">
          <router-view v-if="isRouterAlive" />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script setup>
  import { computed, ref, provide, nextTick, onMounted, onUnmounted } from 'vue'
  import { useRoute } from 'vue-router'
  import { useThemeStore } from '@/store/modules/projectConfig'
  import PageHeader from './components/PageHeader.vue'
  import SiderMenu from './components/SiderMenu.vue'
  import Breadcumb from './components/Breadcumb.vue'

  const route = useRoute()
  const theme = useThemeStore()

  // theme.getBrandLogo()

  const props = defineProps({
    sider: {
      type: Boolean,
      default: true
    },
    breadcrumb: {
      type: Boolean,
      default: true
    }
  })

  // 主体视图 重新加载
  const isRouterAlive = ref(true)
  const reloadApp = async () => {
    isRouterAlive.value = false
    await nextTick()
    isRouterAlive.value = true
  }
  provide('reloadApp', reloadApp)

  const collapsed = ref(false)

  const contentStyle = computed(() => {
    return props.sider || props.breadcrumb ? { padding: '0 12px 12px' } : null
  })

  const MODULE_NAME = {
    unifiedCenter: '统一中心',
    application: '应用管理',
    accountCenter: '账号中心',
    notice: '消息中心',
    corporateCulture: '企业文化',
    environmentConfig: '环境配置'
  }

  const moduleName = computed(() => {
    const name = route.meta.module
    return MODULE_NAME[name]
  })

  const scrollTopHeader = ref(0)
  const computePosition = () => {
    const { scrollTop } = document.querySelector('.bl-content')
    scrollTopHeader.value = scrollTop
    const header = document.querySelector('.bl-index')
    if (header) {
      header.style.backgroundColor =
        scrollTopHeader.value <= 100
          ? 'transparent'
          : `rgba(255, 255, 255,${scrollTop / (scrollTop + 80)})`
      header.style.borderBottom =
        scrollTopHeader.value <= 100 ? 'none' : '1px solid #f0f0f0'
    }
  }

  window.addEventListener('scroll', computePosition, true)

  onUnmounted(() => {
    window.removeEventListener('scroll', computePosition, true)
  })

  onMounted(computePosition)
</script>

<style lang="less" scoped>
  .base-layout {
    width: 100vw;
    height: 100vh;

    .bl-header {
      height: 56px;
      line-height: 56px;
      padding: 0;
      background: @header-background !important;
      // background: #fff;
    }
    //首页样式
    .bl-index {
      height: 56px;
      line-height: 56px;
      padding: 0;
      position: fixed;
      z-index: 999;
      width: 100%;
      // background: transparent;
      background: #fff !important;
    }
    .bl-body {
      .bl-sider {
        //
        .bl-s-title {
          display: flex;
          align-items: center;
          height: 50px;
          line-height: 1;
          padding-left: 20px;
          .text {
            margin-left: 12px;
            font-size: 16px;
            color: #000;
            white-space: nowrap;
          }
          .icon {
            color: #000;
            font-size: 20px;
          }
        }
      }
      .bl-content {
        overflow-y: auto;

        .bl-c-view {
          min-height: auto;
          background: #fff;
        }
      }
    }
  }
</style>
