<script setup>
  import { ref, onMounted, watch } from 'vue'
  import { useRouter, useRoute } from 'vue-router'

  const props = defineProps({
    hideActive: {
      type: Boolean,
      default: true
    }
  })

  // 点击菜单
  const router = useRouter()
  const route = useRoute()
  const activeMenu = ref([])
  // 点击菜单
  // eslint-disable-next-line consistent-return
  // const onClickSubMenu = async (item) => {
  //   if (!userStore.getToken) {
  //     router.push({ name: 'Login' })
  //     return false
  //   }
  //   const { clientId, menuName } = item
  //   if (clientId) {
  //     router.push({
  //       name: 'ChildApp',
  //       params: { clientId }
  //     })
  //   } else {
  //     message.info(`${menuName} 正在开发中，敬请期待。`)
  //   }
  // }

  const emit = defineEmits(['update'])
  const onClickSubMenu = async (item) => {
    emit('update', true)
    router.push({ path: item.path })
    activeMenu.value = [item.path]
  }

  // 获取菜单
  const menu = ref([
    {
      clientId: '1',
      menuId: '1',
      menuName: '首页',
      path: '/index'
    },
    {
      clientId: '2',
      menuId: '2',
      menuName: '应用市场',
      path: '/applicationMarket'
    },
    // {
    //   clientId: '3',
    //   menuId: '3',
    //   menuName: 'AI数智人',
    //   path: ''
    // },
    {
      clientId: '4',
      menuId: '4',
      menuName: '成功案例',
      path: '/caseIndex'
    },
    {
      clientId: '5',
      menuId: '5',
      menuName: '客户支持',
      path: '/clientSupport'
    }
  ])
  // const getMenu = async () => {
  //   try {
  //     menu.value = await getHomeMenu()
  //   } catch (error) {
  //     //
  //   }
  // }
  // getMenu()

  // const activeMenu = computed(() => {
  //   const { name, params,path } = route
  //   console.log(route,'打印params的值')
  //   // console.log( name === 'ChildApp' ? [params.clientId] : [name],'打印name的值')
  //   console.log(path,'path')
  //   return name === 'ChildApp' ? [params.clientId] : [path]
  // })

  watch(
    () => props.hideActive,
    (v) => {
      if (!v) {
        activeMenu.value = []
      }
    },
    { immediate: true }
  )

  onMounted(() => {
    activeMenu.value = [route.path]
  })
</script>

<template>
  <!-- v-if="route.name=='HomeIndex'" -->
  <!-- <a-menu
    :selected-keys="activeMenu"
    theme="light"
    mode="horizontal"
    class="nav-menu"
  >
    <a-menu-item key="HomeIndex" @click="router.push({ name: 'HomeIndex' })">
      首页
    </a-menu-item>
    <a-menu-item key="case" @click="router.push({ name: 'CaseIndex' })">
      成功案例
    </a-menu-item>
    <a-menu-item key="client" @click="router.push({ name: 'ClientSupport' })">
      客户支持
    </a-menu-item>
    <a-sub-menu
      v-for="item in menu"
      :key="item.menuId"
      popup-class-name="nav-menu-popup"
    >
      <template #title>{{ item.menuName }}</template>
      <a-menu-item-group>
        <a-menu-item
          v-for="child in item.children"
          :key="child.clientId"
          @click="onClickSubMenu(child)"
        >
          {{ child.menuName }}
        </a-menu-item>
      </a-menu-item-group>
    </a-sub-menu>
  </a-menu> -->
  <a-menu
    :selected-keys="activeMenu"
    theme="light"
    mode="horizontal"
    class="nav-menu"
  >
    <a-menu-item
      v-for="item in menu"
      :key="item.path"
      popup-class-name="nav-menu-popup"
      @click="onClickSubMenu(item)"
    >
      {{ item.menuName }}
    </a-menu-item>
    <!-- <a-sub-menu
      v-for="item in menu"
      :key="item.menuId"
      popup-class-name="nav-menu-popup"
    >
      <template #title>{{ item.menuName }}</template>
      <a-menu-item-group>
        <a-menu-item
          v-for="child in item.children"
          :key="child.clientId"
          @click="onClickSubMenu(child)"
        >
          {{ child.menuName }}
        </a-menu-item>
      </a-menu-item-group>
    </a-sub-menu> -->
  </a-menu>
</template>

<style lang="less" scoped>
  .nav-menu {
    background: none !important;
    &.ant-menu {
      &.ant-menu-dark {
        background: transparent;
      }
    }
    :deep(.ant-menu-item-selected),
    :deep(.ant-menu-item:hover) {
      background: none !important;
      color: @primary-color !important;
    }
  }
</style>
