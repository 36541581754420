import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouterGuards } from './guards'
import { constantRoutes } from './constantRoutes'
import routes from './modules/buy'
import sellerRoutes from './modules/seller'

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes.concat(routes).concat(sellerRoutes)
})

export const setupRouter = (app) => {
  app.use(router)
  createRouterGuards(router)
}

// 重置路由
export const resetRouter = () => {
  const newRouter = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes
  })
  router.matcher = newRouter.matcher
}

export default router
