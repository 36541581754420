import request from '@/utils/request'

/**
 * 订单列表
 */
export const fetchAppList = (data) => {
  return request.post('/hzcloud-platform/hzcloud-admin/app/list', data)
}

// ---------->导出
export const exportExcel = (data) => {
  return request.post('/hzcloud-platform/hzcloud-admin/app/export', data)
}

// ---------->操作(type: 1-下架 2-撤销 3-删除)
export const fetchOperationApp = (data) => {
  return request.post('/hzcloud-platform/hzcloud-admin/app/operation', data)
}

// 验证卖家入驻
export const validSellerJoin = (buyerId) => {
  return request.get(
    `/hzcloud-platform/hzcloud-admin/buyer-auth/stayingBusinessCheck/${buyerId}`
  )
}

// 卖家入驻申请
export const applyJoinSeller = (data = {}) => {
  return request.post(
    `/hzcloud-platform/hzcloud-admin/buyer-auth/stayingBusiness`,
    data
  )
}

// ---------->新增
export const fetchSaveApp = (data) => {
  return request.post('/hzcloud-platform/hzcloud-admin/app/save', data)
}

// ---------->详情
export const fetchAppDetail = (id) => {
  return request.get(`/hzcloud-platform/hzcloud-admin/app/detail/${id}`)
}

// ---------->销售详情
export const fetchSaleDetail = (id) => {
  return request.get(`/hzcloud-platform/hzcloud-admin/app/detail/sale/${id}`)
}

// ---------->审核、驳回
export const fetchAuditApp = (data) => {
  return request.post('/hzcloud-platform/hzcloud-admin/app/audit', data)
}

// 应用分类
export const fetchGetAppCategoryList = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/app/appCategory/listPage',
    data
  )
}

// 字典
export const fetchGetDictionaryDetail = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/dictionaries/detail/list',
    data
  )
}

// 应用标签
export const fetchGetAppTagList = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/app/appTag/listPage',
    data
  )
}

// 适用规模
export const fetchGetAppScaleList = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/app/appScale/listPage',
    data
  )
}

// 适用行业
export const fetchGetAppIndustryList = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/app/appProfession/listPage',
    data
  )
}

// 适用场景
export const fetchGetAppSceneList = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/app/appScene/listPage',
    data
  )
}

// 客服列表
export const fetchServiceList = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/customerService/listPage',
    data
  )
}

// 客服详情
export const fetchServiceDetail = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/customerService/getById',
    data
  )
}

// 客服删除
export const fetchServiceDelete = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/customerService/delete',
    data
  )
}

// 客服新增
export const fetchServiceSave = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/customerService/save',
    data
  )
}

// 客服更新
export const fetchServiceUpdate = (data) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/customerService/update',
    data
  )
}

// 客户订单列表
export const getCustomOrder = (data = {}) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/order/order/customerOrders',
    data
  )
}

// 导出客户/我的订单列表
export const exportOrder = (data = {}) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/order/order/export',
    data
  )
}

// 我的订单列表
export const getMyOrder = (data = {}) => {
  return request.post(
    '/hzcloud-platform/hzcloud-admin/order/order/listPage',
    data
  )
}
