import request from '@/utils/request'

/**
 * 账号登录
 * @param {Object} data 账号密码
 */
export const loginAccount = (data) =>
  request.post('/ark-admin/api/v1/login', data)

/**
 * 手机登录
 * @param {Object} data 手机号、短信验证码
 */
export const loginMobile = (data) =>
  request.post('/hzcloud-platform/hzcloud-admin/buyer/login', data)

/**
 * 企业微信扫码登录
 * @param {Object} code 企业扫码返回code
 */
export const loginWecom = (code) =>
  request.post('/ark-admin/api/v1/login/wecom', { code })

/**
 * 登出
 * @param {Object}
 */
export const logout = () => {
  return request.post('/hzcloud-platform/hzcloud-admin/buyer/loginOut')
}

/**
 * 验证用户名是否存在
 * @param {String} username 用户名
 */
export const checkHasUsername = (username) => {
  return request.post('/ark-admin/api/v1/validUsername', { username })
}

/**
 * 验证手机号是否存在
 * @param {String} username 用户名
 */
export const checkHasPhoneNumber = (data) => {
  // return request.post('/ark-admin/api/v1/validPhone', data)
  return request.post('/hzcloud-platform/hzcloud-admin/captcha/sendSms', data)
}

/**
 * 注册账号
 * @param {Object} data 注册表单数据
 */
export const registerAccount = (data) => {
  return request.post('/hzcloud-platform/hzcloud-admin/buyer/register', data)
}

/**
 * 忘记密码：校验手机号
 */
export const checkForgetPasswordPhone = (data) => {
  // return request.post('/ark-admin/api/v1/forgetPwd/valid', data)
  // return request.post('/hzcloud-platform/hzcloud-admin/sms/sendSms', data)
}

/**
 * 忘记密码：修改密码
 */
export const updateForgetPassword = (data) => {
  return request.post('/ark-admin/api/v1/forgetPwd', data)
}

/**
 * 修改密码：校验手机号
 */
export const checkUpdatePasswordPhone = (data) => {
  return request.post('/ark-admin/api/v1/users/updatePwd/valid', data)
}

/**
 * 修改密码：修改密码
 */
export const updatePassword = (data, userId) => {
  return request.put(`/ark-admin/api/v1/users/${userId}/updatePwd`, data)
}

/**
 * 修改手机号：验证原手机号
 */
export const validUpdatePhone = (data) => {
  return request.post('/ark-admin/api/v1/users/updatePhone/valid', data)
}

/**
 * 修改手机号：提交修改
 */
export const updatePhone = (data, userId) => {
  return request.put(`/ark-admin/api/v1/users/${userId}/updatePhone`, data)
}

/**
 * 绑定手机号
 */
export const bindPhone = (data, userId) => {
  return request.put(`/ark-admin/api/v1/users/${userId}/bindPhone`, data)
}

/**
 * 修改账号信息
 */
export const updateAccountInfo = (data, userId) => {
  return request.put(`/ark-admin/api/v1/users/${userId}/account`, data)
}

/**
 * 获取权限菜单
 */
export const getPermissionMenu = () => {
  return request.get(`/ark-admin/api/v1/index/menu/permissions`)
}

// ---------->判断华制云是否登录
export function fetchIsLogin() {
  return request.get('/hzcloud-platform/hzcloud-admin/oauth2/hzcloud/isLogin')
}
