import { useAsyncRoute } from '@/store/modules/asyncRoute'

export const permission = {
  mounted(el, binding) {
    if (binding.value === undefined) return
    const asyncRoutes = useAsyncRoute().getAsyncRoutes
    const hasPermission = asyncRoutes.some((route) => {
      return route.meta.permissions === binding.value
    })
    if (!hasPermission) {
      el.remove()
    }
  }
}

export default {
  permission
}
