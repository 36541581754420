/*
 * @Description 加载 Ant Design Icon
 */
import {
  PlusOutlined,
  FormOutlined,
  FileSearchOutlined,
  CopyOutlined,
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined,
  DownOutlined,
  UpOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  TagOutlined,
  FolderOpenOutlined,
  UserOutlined,
  LockOutlined,
  GithubOutlined,
  RightOutlined,
  MobileOutlined,
  MessageOutlined,
  CloseCircleFilled,
  WarningFilled,
  ProfileOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
  MinusCircleOutlined,
  CodeSandboxOutlined,
  SettingOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  RedoOutlined,
  CloseOutlined,
  CheckOutlined,
  EnvironmentOutlined,
  BellOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  LaptopOutlined,
  TeamOutlined,
  AppstoreOutlined,
  GlobalOutlined,
  LeftOutlined,
  ControlOutlined,
  CheckCircleFilled
} from '@ant-design/icons-vue'

const useAntdIcon = (app) => {
  app.component('PlusOutlined', PlusOutlined)
  app.component('FormOutlined', FormOutlined)
  app.component('FileSearchOutlined', FileSearchOutlined)
  app.component('CopyOutlined', CopyOutlined)
  app.component('DeleteOutlined', DeleteOutlined)
  app.component('UploadOutlined', UploadOutlined)
  app.component('DownloadOutlined', DownloadOutlined)
  app.component('DownOutlined', DownOutlined)
  app.component('UpOutlined', UpOutlined)
  app.component('SearchOutlined', SearchOutlined)
  app.component('UnorderedListOutlined', UnorderedListOutlined)
  app.component('TagOutlined', TagOutlined)
  app.component('FolderOpenOutlined', FolderOpenOutlined)
  app.component('UserOutlined', UserOutlined)
  app.component('LockOutlined', LockOutlined)
  app.component('GithubOutlined', GithubOutlined)
  app.component('RightOutlined', RightOutlined)
  app.component('MobileOutlined', MobileOutlined)
  app.component('MessageOutlined', MessageOutlined)
  app.component('CloseCircleFilled', CloseCircleFilled)
  app.component('WarningFilled', WarningFilled)
  app.component('ProfileOutlined', ProfileOutlined)
  app.component('ExclamationCircleOutlined', ExclamationCircleOutlined)
  app.component('ExclamationCircleFilled', ExclamationCircleFilled)
  app.component('CodeSandboxOutlined', CodeSandboxOutlined)
  app.component('SettingOutlined', SettingOutlined)
  app.component('MinusCircleOutlined', MinusCircleOutlined)
  app.component('LeftCircleOutlined', LeftCircleOutlined)
  app.component('RightCircleOutlined', RightCircleOutlined)
  app.component('PhoneOutlined', PhoneOutlined)
  app.component('MailOutlined', MailOutlined)
  app.component('RedoOutlined', RedoOutlined)
  app.component('CloseOutlined', CloseOutlined)
  app.component('CheckOutlined', CheckOutlined)
  app.component('EnvironmentOutlined', EnvironmentOutlined)
  app.component('BellOutlined', BellOutlined)
  app.component('EyeOutlined', EyeOutlined)
  app.component('EyeInvisibleOutlined', EyeInvisibleOutlined)
  app.component('LaptopOutlined', LaptopOutlined)
  app.component('TeamOutlined', TeamOutlined)
  app.component('AppstoreOutlined', AppstoreOutlined)
  app.component('GlobalOutlined', GlobalOutlined)
  app.component('LeftOutlined', LeftOutlined)
  app.component('ControlOutlined', ControlOutlined)
  app.component('CheckCircleFilled', CheckCircleFilled)
}

export default useAntdIcon
