import request from '@/utils/request'

/**
 *
 * 实时消息刷新
 */
export const getMessageFresh = (params) =>
  request.get(`/ark-admin/api/v1/message/fresh/${params.userId}`)

/**
 *
 * 获取消息列表
 */
export const getMessageList = (params) =>
  request.get(`/ark-admin/api/v1/message/${params.userId}`, { params })

/**
 *
 * 删除
 */
export const deleteMeaasge = (data) =>
  request.delete(`/ark-admin/api/v1/message`, { data })

/**
 *
 * 标记已读
 */
export const postMessageSign = (params) =>
  request.post(`/ark-admin/api/v1/message`, params)

/**
 *
 * 消息详情
 */
export const getMessageDetail = (params) =>
  request.get(`/ark-admin/api/v1/message/${params.userId}/${params.detailId}`)
