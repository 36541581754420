import Baselayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/sellerCenter',
    // redirect: '/sellerCenter',
    name: 'SellerCenter',
    meta: {
      title: '卖家中心'
      // icon: 'AppstoreOutlined',
      // module: 'sellerCenter',
      // permissions: 'ARKyygl'
    },
    props: (route) => {
      return route.meta.parentProps || {}
    },
    component: Baselayout,
    children: [
      {
        path: 'sellerIndex',
        name: 'sellerIndex',
        meta: {
          title: '应用管理',
          hidden: true,
          activeMenu: '/sellerIndex'
        },
        component: () => import('@/views/sellerCenter/index.vue')
      },
      {
        path: 'applyJoin',
        name: 'ApplyJoin',
        meta: {
          title: '卖家入驻申请',
          parentProps: { sider: false, breadcrumb: false }
        },
        component: () => import('@/views/sellerCenter/applyFor/ApplyJoin.vue')
      },
      {
        path: 'applyProgress',
        name: 'ApplyProgress',
        meta: {
          title: '入驻申请进度',
          parentProps: { sider: false, breadcrumb: false }
        },
        component: () =>
          import('@/views/sellerCenter/applyFor/ApplyProgress.vue')
      },
      {
        path: 'sellerEdit/:type(add|edit|check)',
        name: 'sellerEdit',
        props: true,
        meta: {
          title: '创建应用',
          hidden: true,
          activeMenu: '/sellerEdit'
        },
        beforeEnter(to, from, next) {
          const nameMap = {
            add: '创建应用',
            edit: '编辑应用',
            check: '审核详情'
          }
          to.matched[1].meta.title = nameMap[to.params.type]
          next()
        },
        component: () => import('@/views/sellerCenter/edit.vue')
      },
      {
        path: 'sellerDetail',
        name: 'sellerDetail',
        props: true,
        meta: {
          title: '应用详情',
          hidden: true,
          activeMenu: '/sellerDetail'
        },
        component: () => import('@/views/sellerCenter/detail.vue')
      },
      {
        path: 'customerService',
        name: 'customerService',
        meta: {
          title: '客服管理',
          hidden: true,
          activeMenu: '/customerService'
        },
        component: () => import('@/views/customerService/index.vue')
      },
      {
        path: 'orderManage',
        name: 'orderManage',
        meta: {
          title: '订单管理'
        },
        component: () => import('@/views/sellerCenter/orderManage/index.vue')
      },
      {
        path: 'orderDetail',
        name: 'orderDetail1',
        meta: {
          title: '订单详情'
        },
        component: () => import('@/views/buyCenter/orderDetail.vue')
      },
      {
        path: 'userManage',
        name: 'userManage',
        meta: {
          title: '账号管理',
          hidden: true,
          activeMenu: '/userManage'
        },
        component: () => import('@/views/sellerCenter/userManage/index.vue')
      },
      {
        path: 'purchased',
        name: 'sellerPurchasedGoods',
        meta: {
          title: '已购买产品'
        },
        component: () => import('@/views/buyCenter/purchasedGoods.vue')
      },
      {
        path: 'invoice',
        name: 'InvoiceIndex',
        meta: {
          title: '发票管理'
        },
        component: () => import('@/views/invoice/invoiceIndex.vue')
      }
    ]
  }
]

export default routes
