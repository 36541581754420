// import BaseLayout from '@/layout/BaseLayout.vue'

const routes = [
  // {
  //   path: '/',
  //   component: BaseLayout,
  //   props: { sider: false, breadcrumb: false },
  //   meta: {
  //     hidden: true
  //   },
  //   children: [
  //     {
  //       path: '/index',
  //       name: 'HomeIndex',
  //       meta: {
  //         title: '首页'
  //       },
  //       component: () => import('@/views/home/HomeIndex.vue')
  //     },
  //     {
  //       path: '/platDetail/:id',
  //       name: 'PlatDetail',
  //       meta: {
  //         title: '平台详情'
  //       },
  //       props: true,
  //       component: () => import('@/views/home/PlatDetail.vue')
  //     },
  //     {
  //       path: '/appDetail',
  //       name: 'APPDetail',
  //       meta: {
  //         title: '工业应用详情'
  //       },
  //       component: () => import('@/views/home/AppDetail.vue')
  //     }
  //   ]
  // }
]

export default routes
