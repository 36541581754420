import Baselayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/identity',
    redirect: '/identity/UserList',
    name: 'Identity',
    meta: {
      title: '身份管理',
      icon: 'UserOutlined',
      module: 'unifiedCenter',
      sort: 1,
      permissions: 'ARKsfgl'
    },
    component: Baselayout,
    children: [
      {
        path: 'UserList',
        name: 'UserList',
        meta: {
          title: '用户'
        },
        props: true,
        component: () => import('@/views/iDentity/UserList.vue')
      },
      {
        path: 'AddUser',
        name: 'AddUser',
        meta: {
          title: '创建用户',
          hidden: true,
          activeMenu: 'UserList'
        },
        component: () =>
          import('@/views/iDentity/components/userList/AddUser.vue')
      },
      {
        path: 'LookUser/:userId',
        name: 'LookUser',
        props: true,
        meta: {
          title: '查看用户',
          hidden: true,
          activeMenu: 'UserList'
        },
        component: () =>
          import('@/views/iDentity/components/userList/LookUser.vue')
      },
      {
        path: 'UserGroup',
        name: 'UserGroup',
        meta: {
          title: '用户组'
        },
        component: () => import('@/views/iDentity/UserGroup.vue')
      },
      {
        path: 'AddUserGroup',
        name: 'AddUserGroup',
        meta: {
          title: '创建用户组',
          hidden: true,
          activeMenu: 'UserGroup'
        },
        component: () =>
          import('@/views/iDentity/components/userGroup/AddUserGroup.vue')
      },
      {
        path: 'LookUserGroup/:groupId/:adminFlag',
        name: 'LookUserGroup',
        props: true,
        meta: {
          title: '查看用户组',
          hidden: true,
          activeMenu: 'UserGroup'
        },
        component: () =>
          import('@/views/iDentity/components/userGroup/LookUserGroup.vue')
      }
    ]
  }
]

export default routes
