import Baselayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/permission',
    redirect: '/permission/permissionPolicy',
    name: 'Permission',
    meta: {
      title: '权限管理',
      icon: 'LaptopOutlined',
      module: 'unifiedCenter',
      sort: 2,
      permissions: 'ARKqxgl'
    },
    component: Baselayout,
    children: [
      {
        // 策略列表
        path: 'permissionPolicy',
        name: 'PermissionPolicy',
        meta: {
          title: '策略管理'
        },
        component: () => import('@/views/permissionManage/PermissionPolicy.vue')
      },
      {
        // 新增策略
        path: 'addByproduct',
        name: 'AddByproduct',
        meta: {
          title: '新增',
          hidden: true,
          activeMenu: 'PermissionPolicy'
        },
        component: () => import('@/views/permissionManage/AddByproduct.vue')
      },
      {
        // 策略详情
        path: 'permissionDetail/:id',
        name: 'PermissionDetail',
        meta: {
          title: '详情',
          hidden: true,
          activeMenu: 'PermissionPolicy'
        },
        component: () => import('@/views/permissionManage/PermissionDetail.vue')
      }
    ]
  }
]

export default routes
