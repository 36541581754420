import store from 'store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { ACCESS_TOKEN } from '@/utils/constant'
import { useUserStore } from '@/store/modules/user'
import { useAsyncRoute } from '@/store/modules/asyncRoute'
import { notFoundRoute } from '@/router/constantRoutes'
import { Storage } from '@/utils/Storage'

const LOGIN_PATH = '/login'
const whiteList = [LOGIN_PATH, '/register', '/forgetPassword']

export const createRouterGuards = (router) => {
  const userStore = useUserStore()
  const asyncStore = useAsyncRoute()
  router.beforeEach(async (to, from, next) => {
    NProgress.start()
    const token = store.get(ACCESS_TOKEN)

    // 白名单
    if (
      whiteList.includes(to.path) ||
      (to.meta.permissions === 'no' && !token)
    ) {
      if (to.path === LOGIN_PATH) {
        // 重置异步路由（权限路由）添加状态
        // 1、当请求接口返回401，跳转登录页时；
        // 2、当用户退出登录，返回登录页时；
        // 必须重置路由添加状态，
        // 确保用户登录后可以重新获取用户信息，
        // 重新生成登录用户拥有权限的路由。
        asyncStore.setAsyncRouteAdded(false)
      }
      next()
      return
    }

    if (!token) {
      /** 重定向到 登录页 */
      const redirectData = {
        name: 'Login',
        replace: true
      }
      // console.log(3333333333, to.path, to)
      if (to.path) {
        if (to.path === '/authorization') {
          redirectData.query = to.query
        } else {
          redirectData.query = {
            ...redirectData.query,
            redirect: to.path
          }
        }
      }
      next(redirectData)
      return
    }

    // 已动态添加过路由
    if (asyncStore.isAsyncRouteAdded) {
      next()
      return
    }

    // 获取用户信息（用户权限）
    const loginId = localStorage.getItem('loginId')
    console.log('loginId', loginId)
    const loginInfo = loginId ? JSON.parse(loginId) : ''
    console.log('loginInfo', loginInfo.id)
    if (loginInfo.id) await userStore.getInfo(loginInfo.id)

    // // 生成路由
    // const routes = await asyncStore.generateRoutes()

    // // // 动态添加可访问路由表
    // routes.forEach((item) => {
    //   router.addRoute(item)
    // })

    // 添加404
    const hasNotFoundRoute = router
      .getRoutes()
      .findIndex((item) => item.name === 'NotFound')
    if (hasNotFoundRoute === -1) {
      router.addRoute(notFoundRoute)
    }

    // 路由重定向
    const redirectPath = from.query.redirect || to.path
    const redirect = decodeURIComponent(redirectPath)
    const nextData =
      to.path === redirect ? { ...to, replace: true } : { path: redirect }
    asyncStore.setAsyncRouteAdded(true)
    next(nextData)
  })

  router.onError((error) => {
    console.log(error)
  })

  router.afterEach(() => {
    NProgress.done()
  })
}

export default {}
