<script setup>
  import { ref } from 'vue'
  import { getWebLoginConfig } from '@/apis'

  import defaultLogo from '@/assets/images/logo@2x.b.png'

  // Logo
  const logoSrc = ref(defaultLogo)
  const getLogo = async () => {
    try {
      const { brandLogo } = await getWebLoginConfig()
      logoSrc.value = brandLogo
    } catch (err) {
      //
    }
  }
  // getLogo()
</script>

<template>
  <div class="layout-single">
    <!-- header -->
    <div class="ls-header">
      <div class="ls-header-left">
        <div class="logo not-select">
          <router-link :to="{ name: 'HomeIndex' }">
            <img :src="logoSrc" alt="LOGO" />
          </router-link>
        </div>
      </div>
      <div class="ls-header-right">
        <router-link :to="{ name: 'Login' }">登录</router-link>
        <router-link :to="{ name: 'Register' }">注册</router-link>
      </div>
    </div>
    <div class="ls-body">
      <router-view />
    </div>
    <div class="ls-footer">
      <div class="copyright">
        <p>
          © 2022 ark.ehzcloud.com 版权所有
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
          >
            粤ICP备15062849号-2
          </a>
        </p>
        <p>深圳华制智能制造技术有限公司</p>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .layout-single {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ls-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      border-bottom: 1px solid #eee;
      .ls-header-left {
        height: 100%;
        padding: 0 12px;
      }
      .ls-header-right {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 100%;
        padding: 0 20px;
        a {
          color: #333;
          &:hover {
            color: @link-color;
          }
        }
      }
      .logo {
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          height: 50px;
        }
      }
    }

    .ls-body {
      flex: 1;
    }

    .ls-footer {
      text-align: center;
      padding: 50px 0;

      a {
        color: #333;
        &:hover {
          color: @link-color;
        }
      }
    }
  }
</style>
