<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
  import { ref } from 'vue'
  import { getWebLoginConfig } from '@/apis'
  // import defaultLogo from '@/assets/images/logo@2x.b.png'
  import defaultLogo from '@/assets/images/hz_logo.png'
  import defaultBackground from './img/bg_w.png'

  const logoSrc = ref(defaultLogo)

  const page = ref({
    loginBanner: '',
    loginBrandDes: '',
    loginBrandName: ''
  })

  const getData = async () => {
    try {
      page.value = await getWebLoginConfig()
    } catch (err) {
      //
    }
  }
  // getData()
</script>

<template>
  <div class="layout-login-register">
    <div
      class="llr-bg"
      :style="{
        'background-image': `url(${page.loginBanner || defaultBackground})`
      }"
    ></div>
    <div class="llr-header">
      <div class="llr-header-left">
        <div class="logo not-select">
          <router-link :to="{ name: 'HomeIndex' }">
            <img :src="logoSrc" alt="LOGO" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="llr-box">
      <!-- <div class="llr-info">
        <h1 class="title">{{ page.loginBrandName }}</h1>
        <div class="line"></div>
        <p class="text">
          {{ page.loginBrandDes }}
        </p>
      </div> -->
      <!-- 登录、注册 -->
      <div class="llr-body">
        <router-view v-slot="{ Component, route }">
          <transition name="fade-transform" mode="out-in">
            <component :is="Component" :key="route.fullpath" />
          </transition>
        </router-view>
      </div>
    </div>
    <!-- <div class="llr-footer">
      <p>
        Copyright © 2022 ark.ehzcloud.com 版权所有
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
          粤ICP备15062849号-2
        </a>
      </p>
      <p>深圳华制智能制造技术有限公司</p>
    </div> -->
  </div>
</template>

<style lang="less" scoped>
  @input-bg: #f8f8f8;
  @placeholder-color: #aaa;
  .layout-login-register {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .llr-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: url(./img/bg_w.png) center no-repeat;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .llr-box {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex: 1;
      z-index: 9;
      padding: 0 12vw;
    }
    .llr-header {
      z-index: 9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      background: #fff;
      .llr-header-left {
        height: 100%;
        padding: 0 12px;
      }
      .logo {
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          height: 38px;
        }
      }
    }
    .llr-info {
      color: #fff;
      flex: 1;
      width: 570px;
      margin-right: 150px;
      .title {
        font-size: 37px;
        font-weight: 500;
        line-height: 1;
        color: #333333;
      }
      .text {
        font-size: 20px;
        font-weight: 300;
        line-height: 2;
        color: #484848;
      }
      .line {
        width: 100px;
        height: 8px;
        margin: 25px 0;
        background: @primary-color;
        border-radius: 5px;
      }
    }

    .llr-body {
      position: relative;
      width: 800px;
      flex: 0 1 auto;
    }

    .llr-footer {
      text-align: center;
      position: relative;
      z-index: 2;
      font-size: 14px;
      padding: 20px 0 60px;
      color: rgba(0, 0, 0, 0.75);
      a {
        color: rgba(0, 0, 0, 0.75);
      }
    }
  }

  @media (max-width: 1455px) {
    .layout-login-register {
      .llr-box {
        padding: 0 10vw;
        .llr-info {
          width: 620px;
          margin-right: 65px;
          .title {
            font-size: 30px;
          }
          .text {
            font-size: 18px;
          }
        }
      }
    }
  }
  @media (max-width: 1280px) {
    .layout-login-register {
      .llr-box {
        padding: 0 5vw;
        .llr-info {
          width: 500px;
          margin-right: 45px;
          .title {
            font-size: 26px;
          }
          .text {
            font-size: 16px;
          }
        }
      }
    }
  }
</style>
