import BaseLayout from '@/layout/BaseLayout.vue'

const routes = [
  {
    path: '/',
    component: BaseLayout,
    props: { sider: false, breadcrumb: false },
    meta: {
      hidden: true
    },
    children: [
      {
        path: '/conPanel',
        name: 'ConPanel',
        meta: {
          title: '控制台',
        },
        component: () => import('@/views/conPanel/conPanel.vue')
      }
    ]
  }
]

export default routes
