
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol  viewBox=\"0 0 32 32\" id=\"icon-Location\"><path d=\"M16 18a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3.003 3.003 0 0 0-3-3z\" fill=\"currentColor\" /><path d=\"m16 30-8.436-9.949a35.076 35.076 0 0 1-.348-.451A10.889 10.889 0 0 1 5 13a11 11 0 0 1 22 0 10.884 10.884 0 0 1-2.215 6.597l-.001.003s-.3.394-.345.447zM8.812 18.395c.002 0 .234.308.287.374L16 26.908l6.91-8.15c.044-.055.278-.365.279-.366A8.901 8.901 0 0 0 25 13a9 9 0 1 0-18 0 8.905 8.905 0 0 0 1.813 5.395z\" fill=\"currentColor\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}